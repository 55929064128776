$root-variables: (
    // primary color
    primary-color: linear-gradient(180deg, #E5342A 0%, #F7AE32 41%, #E63E24 100%) 0% 0%,
    primary-color-invert: #{lighten(#F7AE32, 100%)},
    primary-color-hover: #{darken(#F7AE32, 7.5%)},

    // secondary color
    secondary-color: #2A2A2A,
    secondary-color-invert: #{lighten(#2A2A2A, 100%)},
    secondary-color-hover: #F7AE32,

    // tertiary color
    tertiary-color: #fff,
    tertiary-color-invert: #F7AE32,
    tertiary-color-hover: #F7AE32,

    // button regular
    button-padding-y: 12px,
    button-padding-x: 24px,
    button-rounded: 4px,

    // button small
    button-sm-padding-y: 8px,
    button-sm-padding-x: 12px,
);

.btn {
    @each $key, $value in $root-variables {
        --#{$key}: #{$value};
    }

    @apply inline-flex items-center text-center border border-transparent font-bold;
    padding: var(--button-padding-y) var(--button-padding-x);
    font-size: var(--button-font-size);
    border-radius: var(--button-rounded);
    outline: 0;
    user-select: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    @each $sizes in sm {
        $button-y-size: var(--button-#{$sizes}-padding-y);
        $button-x-size: var(--button-#{$sizes}-padding-x);

        &-#{$sizes} {
            padding: $button-y-size $button-x-size;
        }
    }
    
    @each $colors in primary, secondary, tertiary {
        $button-color: var(--#{$colors}-color);
        $button-color-invert: var(--#{$colors}-color-invert);
        $button-color-hover: var(--#{$colors}-color-hover);

        &-#{$colors} {
            background: $button-color !important;
            color: $button-color-invert;

            svg {
                fill: var(--#{$colors}-color-invert);
            }

            &:hover {
                @apply underline;
            }
        }

        &-outline-#{$colors} {
            @apply bg-white;
            border-color: $button-color;
            color: $button-color;

            svg {
                fill: $button-color;
            }

            &:hover {
                border-color: $button-color-hover;

                svg {
                    fill: $button-color;
                }
            }
        }
    }
}