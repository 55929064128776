.contact {
    
    &--page {
        background-image: url('../images/Group14.png');
        background-position: left 24%;

        @include media-breakpoint-down(sm) {
            background-size: 50%;
        }
        
        @include media-breakpoint-up(md) {
            @apply mt-24;
        }

        @include media-breakpoint-up(lg) {
            background-position: left 135%;
        }
    }

    &--success {
        background: linear-gradient(0deg, #FFFFFF 0%, #e9e9e9 100%);
        margin-top: var(--navbar-height);
        @apply border-b border-b-primary;

        @include media-breakpoint-up(lg) {
            height: calc(100vh - var(--navbar-height));
        }
    }

    &-body {

        &__left {
            position: sticky;
            top: calc(var(--navbar-height) * 1.5);

            > h3 {
                @apply mb-4 lg:text-4xl;
            }
        }

        .address {

            &-item {
                
                &__icon {
                    @apply w-5;

                    svg {
                        @apply mx-auto;
                    }
                }
            }
        }

        .maps {
            @apply relative rounded-sm overflow-hidden;
            height: calc(475px - 15vh);

            @include media-breakpoint-up(lg) {
                height: 475px;
            }

            &::before {
                content: '';
                background: transparent linear-gradient(98deg, #E5342A 0%, #F7AE32 41%, #E63E24 100%);
                @apply absolute top-0 left-0 right-0 h-4;
            }

            > * {
                @apply w-full h-full;
            }
        }

        .talk {

            > h5 {
                @apply mb-10 lg:mb-14 text-center;
            }
        }
    }

    &-success {

        &__body {

            @include media-breakpoint-up(lg) {
                height: calc(100vh - var(--navbar-height));
            }

            > .image {

                @include media-breakpoint-up(lg) {
                    @apply absolute top-0 left-0 w-1/2 h-full;
                }

                img {
                    @apply w-full h-full object-cover;
                }
            }

            .content {
                @apply lg:ml-auto lg:pl-25 lg:w-1/2;

                @include media-breakpoint-down(lg) {
                    @apply py-8;
                }
            }
        }
    }
}