$root-variables: (
    // prefix
    prefix-space: calc(30px + (12px + 12px)),
);

.form {

    &-input, &-textarea, &-select {
        @apply w-full px-3 py-2 text-black border-gray rounded-sm transition ease-in-out ring-0 placeholder:text-graySemi focus:border-primary #{!important};

        &[disabled] {
            @apply bg-gray;
        }

        .form-prefix & {
            padding-left: calc(var(--form-prefix-space) + 12px) #{!important};
        }
    }

    &-validate {

        .form {

            &-input, &-textarea, &-select {
                @apply border-primary #{!important};
            }
        }

        &__feedback {
            @apply mt-1 text-primary;

            small {
                @apply text-sm;
            }
        }
    }

    &-prefix {
        @each $key, $value in $root-variables {
            --form-#{$key}: #{$value};
        }

        &__icon {
            @apply absolute top-1/2 inline-flex items-center justify-center border-r border-gray;
            width: var(--form-prefix-space);
            height: calc(100% - 12px);
            transform: translateY(-50%);
        }
    }

    &-captcha {
        
        @include media-breakpoint-down(lg) {
            transform: scale(.9);
        }
    }
}