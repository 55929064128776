$slick-loader-path: "/assets/js/vendor/slick/" !default;
$slick-font-path: "/assets/js/vendor/slick/fonts/" !default;

@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

.slick {
    @apply opacity-0;

    &.slick-initialized {
        @apply opacity-100;
    }

    &--featured {
        @apply mx-1 lg:-mx-4;
    }

    &-dotted {

        &.slick-slider {
            
            &.slick--home {
                @apply mb-0;
            }

            &.slick--featured {
                @apply mb-10 lg:mb-16;
            }
        }
    }

    &-slide {

        .slick--home & {

            .item {

                &-dialog {
                    @apply aspect-[1/1.5] lg:aspect-[8/3.5];
                }

                &-body {
                    @apply lg:pt-24 xl:pt-[125px];

                    .image {
                        @apply w-full lg:w-[55%] p-4;
                    }

                    .content {
                        @apply w-full lg:w-[45%] p-4;

                        h2 {
                            @apply xl:text-8xl;
                        }

                        p {
                            @apply line-clamp-3 h-18;
                        }

                        &-actions {
                            @apply mt-6 lg:mt-10;
                        }
                    }
                }

                &-image {
                    @apply lg:aspect-[8/3.5] prose-img:w-full prose-img:h-full prose-img:object-center prose-img:object-cover;
                }
            }
        }

        .slick--featured &, .slick--factory & {
            @apply mx-1 lg:mx-4;
        }

        .slick--browse & {
            @apply mx-1 lg:mx-4 pt-4 pb-8;

            .item {
                @apply block lg:flex p-6 lg:px-10 lg:py-12;
                box-shadow: 0px 8px 32px rgba($color: #000000, $alpha: .1);
                
                @include media-breakpoint-down(sm) {
                    @apply text-center;
                }

                @include media-breakpoint-up(lg) {
                    width: 600px;
                }

                &:hover {

                    .item-content__actions {

                        &::before {
                            @apply bg-primary;
                        }

                        .icon {
                            @apply ml-3;
                        }
                    }
                }

                &::before {
                    content: '';
                    background: transparent linear-gradient(98deg, #E5342A 0%, #F7AE32 41%, #E63E24 100%);
                    @apply absolute top-0 left-0 right-0 h-4;
                }

                &-image {

                    img {
                        width: 150px;
                    }

                    @include media-breakpoint-down(sm) {

                        img {
                            @apply mx-auto;
                        }
                    }
                }

                &-content {
                    @apply flex-1;

                    @include media-breakpoint-down(sm) {
                        @apply mt-6;

                        &__title {
                            
                            h3 {
                                height: 50px;
                            }
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        @apply pl-12;
                    }

                    &__title {
                        @apply mb-4 lg:mb-6;

                        h3 {
                            @apply text-1xl lg:text-2xl line-clamp-2 lg:h-15;
                        }
                    }

                    &__actions {
                        @apply text-primary fill-primary font-bold;
                        transition: margin 2.5s ease-in-out;

                        &::before {
                            content: '';
                            @apply bg-transparent absolute right-0 left-0 w-full transition ease-in-out;
                            bottom: -2px;
                            height: 2px;
                        }
                    }
                }
            }
        }

        .slick--factory & {

            .item {

                &-image {
                    @apply aspect-video rounded-sm overflow-hidden;

                    img {
                        @apply w-full h-full object-cover object-center;
                    }
                }
            }
        }
    }

    &-prev, &-next {
        @apply z-30 before:hidden;

        &.slick-disabled {
            @apply opacity-50;
        }
        
        .slick--home &, .slick--featured &, .slick--browse &, .slick--factory & {
            @apply w-10 h-10 lg:w-14 lg:h-14 rounded-full;
            background: linear-gradient(180deg, #E5342A 0%, #F7AE32 41%, #E63E24 100%) 0% 0%;
        }

        .slick--home &, .slick--featured & {
            top: calc(50% + 44px);
        }
        
        .slick--browse & {
            top: unset;
            transform: translateY(0);

            @include media-breakpoint-up(lg) {
                bottom: -58px;
            }
        }
    }

    &-prev {
        
        .slick--home & {
            @apply left-2 lg:left-5;
        }
        
        .slick--featured &, .slick--factory & {
            @apply left-2 lg:-left-12;
        }

        .slick--browse & {

            @include media-breakpoint-up(lg) {
                left: calc(50% - 150px);
            }
        }
    }

    &-next {
        
        .slick--home & {
            @apply right-2 lg:right-5;
        }
        
        .slick--featured &, .slick--factory & {
            @apply -right-2 lg:-right-12;
        }

        .slick--browse & {

            @include media-breakpoint-up(lg) {
                right: calc(50% - 150px);
            }
        }
    }
    
    &-dots {

        .slick--home &, .slick--featured &, .slick--browse &, .slick--factory & {

            > li {
                @apply w-2 h-2;

                &.slick-active {
                    @apply w-4 h-4;

                    button {
                        @apply bg-primary;
                    }
                }

                button {
                    @apply w-full h-full p-0 bg-graySemi rounded-none;
                }
    
                button {
                    @apply before:hidden;
                }
            }
        }

        .slick--home & {
            @apply bottom-4;
        }

        .slick--featured &, .slick--browse &, .slick--factory & {
            @apply -bottom-10;
        }
    }
}