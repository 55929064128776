.speciality {
    
    &--page {
        background-image: url('../images/Group14.png'), url('../images/Group4.png');
        background-position: left 135%, 160% 160%;

        @include media-breakpoint-up(lg) {
            @apply mt-18;
        }

        @include media-breakpoint-up(xl) {
            @apply mt-24;
        }
    }

    &-body {

        > .title {
            @apply lg:prose-headings:text-2xl prose-headings:italic;
        }

        > .listing {

            @include media-breakpoint-up(md) {

                > div {

                    &:nth-of-type(even) {
    
                        .media {
    
                            &-image {
                                @apply order-2;
    
                                img {
                                    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 85%);
                                }
                            }
    
                            &-content {
                                @apply -mr-12 ml-0 order-1;
                            }
                        }
                    }
                }
            }
        }
    }
}