.post {

    &-detail {
        @apply prose-headings:mb-4 prose-p:mb-4 prose-a:text-primary prose-a:font-bold prose-img:mb-4 prose-ul:mb-4 prose-ol:mb-4 prose-ul:pl-4 prose-ol:pl-4 prose-ul:list-disc prose-ol:list-decimal;

        p {

            &:last-child {
                margin-bottom: 0 #{!important};
            }
        }

        img {
            @apply inline-block rounded-sm;
        }
    }
}