.breadcrumb {
    @apply mb-1;

    &-item {
        @apply text-sm;

        + .breadcrumb-item {
            @apply pl-1;

            &::before {
                content: '/';
                @apply inline-block float-left pr-1 text-black;
            }
        }

        &.active {
            @apply text-primary;
        }
    }
}