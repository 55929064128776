@import "~choices.js/src/styles/choices.scss";

.choices {

    &[data-type*=select-one] {

        &::before {
            content: '';
            @apply absolute top-1/2 right-3 w-[1.5em] h-[1.5em] bg-no-repeat;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
            background-size: 1.5em 1.5em;
            transform: translateY(-50%);
        }

        &::after {
            @apply hidden;
        }

        .choices {

            &__inner {
                @apply pb-2;
            }
        }
    }

    &__inner {
        @apply bg-white min-h-[auto] px-3 py-2 border-graySemi rounded-none;
        font-size: inherit;

        .is-focused &, .is-open & {
            @apply border-primary rounded-none;
        }
    }

    &__list {
        @apply p-0;

        &[aria-expanded] {
            @apply shadow-md rounded-none;
        }

        &--dropdown {
            @apply shadow-md rounded-none;
        }
    }

    &__placeholder {
        @apply text-graySemi opacity-100;
    }
}