.pagination {

    &-item {

        &__button {
            @apply text-black font-bold;
            line-height: 24px;

            &.current {
                @apply text-primary lg:text-2xl;
            }

            &.disabled {
                @apply text-gray;
            }
        }
    }
}