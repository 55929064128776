.jumbotron {
    padding-top: var(--navbar-height);

    @include media-breakpoint-down(md) {
        padding-bottom: calc(var(--navbar-height) / 2);
    }

    &::before {
        content: '';
        background: linear-gradient(0deg, #FFFFFF 0%, #E9E9E9 100%);
        @apply absolute right-0 left-0 h-full;
        top: var(--navbar-height);
        z-index: -1;

        @include media-breakpoint-up(md) {
            height: 650px;
        }
    }

    &-image {

        img {
            @apply mix-blend-multiply;
        }
        
        @include media-breakpoint-down(sm) {
            @apply aspect-video overflow-hidden;

            img {
                @apply w-full h-full object-cover object-bottom;
            }
        }

        @include media-breakpoint-up(md) {
            @apply absolute right-0;
            top: var(--navbar-height);

            img {
                height: 300px;
            }
            
            .jumbotron--news & {
                top: 0;

                img {
                    height: 450px;
                }
            }
        }
        
        @include media-breakpoint-up(xl) {

            img {
                height: 350px;
            }
        }
    }

    &-body {

        @include media-breakpoint-down(md) {

            .jumbotron--search & {
                @apply mt-8;
            }
        }

        @include media-breakpoint-up(md) {
            @apply pt-16;
        }
    
        &__title {
    
            > h2 {
                @apply mb-4 lg:text-8xl uppercase;

                .jumbotron--search & {
                    @apply lg:text-4xl;
                    text-transform: none;

                    span {
                        @apply relative font-bold;

                        &::before, &::after {
                            content: '"';
                            @apply relative inline-block;
                        }
                    }
                }
            }

            p {

                .jumbotron--product & {
                    @apply lg:w-[45%];
                }
            }
        }
    }
}