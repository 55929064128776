.card {
    @apply transition ease-in-out;

    &--speciality {
        @apply p-6;
        box-shadow: 0 8px 24px rgba($color: #000000, $alpha: .15);

        @include media-breakpoint-up(sm) {
            @apply py-10 px-4;
        }

        &:hover {

            .card {

                &-header {
                    @apply opacity-0;
                }

                &-content {
                    @apply opacity-100;
                }
            }
        }
    }

    &--featured {
        @apply border border-transparent;

        &:hover {
            @apply border-primary;

            .card {

                &-content {

                    &__actions {
                        @apply opacity-100;
                    }
                }
            }
        }
    }

    &--list {
        box-shadow: 0px 8px 32px rgba($color: #000000, $alpha: .1);

        @include media-breakpoint-down(sm) {
            @apply text-center;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 40px + 16px #{!important};
        }

        &::before {
            content: '';
            background: linear-gradient(100deg, #E5342A 0%, #F7AE32 41%, #E63E24 100%);
            @apply absolute top-0 right-0 left-0 h-4;
        }
    }

    &--news {
        
        &:hover {

            .card {

                &-header {
                    
                    img {
                        @apply scale-110;
                    }
                }
            }
        }
    }

    &--gallery {
        border-radius: 24px;
        box-shadow: 0px 8px 24px rgba($color: #000000, $alpha: .1);
    }

    &-header {

        .card--speciality & {
            @apply flex flex-col items-center justify-center h-full opacity-100 transition ease-in-out;

            h3 {
                @apply mt-4 text-1xl h-[50px];

                @include media-breakpoint-up(sm) {
                    @apply line-clamp-2;
                }

                // @include media-breakpoint-up(lg) {
                //     @apply h-15;
                // }
            }
        }

        .card--news &, .card--list & {

            img {
                @apply w-full h-full object-cover object-center;
            }
        }

        .card--news & {
            
            img {
                transition: transform .5s ease-in-out;
            }
        }

        .card--list & {
            width: 150px;
            height: 150px;

            @include media-breakpoint-down(sm) {
                @apply mx-auto mb-4;
            }
        }

        .card--gallery & {

            img {
                @apply mx-auto;
                height: 150px;
            }
        }
    }

    &-content {

        .card--speciality & {
            @apply absolute top-0 right-6 left-6 flex items-center justify-center text-center h-full opacity-0 transition ease-in-out;
        }

        .card--news & {

            > p {
                @apply line-clamp-3 h-18;
            }
        }

        .card--list & {
            @apply flex-1 sm:pl-0 pl-16;
        }

        &__title {

            .card--news & {

                h6 {
                    @apply line-clamp-2 h-10;
                }
            }

            .card--news-trending & {

                h6 {
                    @apply lg:text-2xl line-clamp-1;

                    @include media-breakpoint-up(lg) {
                        height: 30px;
                    }
                }
            }

            .card--featured & {
    
                > h5 {
                    @apply mb-2 line-clamp-2 h-10 lg:h-[50px];

                    @include media-breakpoint-down(lg) {
                        @apply text-rg;
                    }
                }
    
                > p {
                    @apply line-clamp-3 h-18;
                }
            }

            .card--list & {
                @apply mb-4 lg:mb-10;

                @include media-breakpoint-down(md) {
                    
                    > h5 {
                        height: 50px;
                    }
                }

                > h5 {
                    @apply lg:text-2xl line-clamp-2 lg:h-15;
                }
            }
        }

        &__actions {
            
            .card--news & {
                @apply text-primary fill-primary font-bold;

                &::before {
                    content: '';
                    @apply bg-primary absolute right-0 left-0 w-full;
                    bottom: -2px;
                    height: 1px;
                }
            }

            .card--featured & {
                @apply bg-primary text-white font-bold w-full py-3 text-center opacity-0 transition-opacity ease-in-out;
            }
        }
    }
}