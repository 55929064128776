.hero {
    margin-top: var(--navbar-height);

    @include media-breakpoint-up(lg) {
        background-size: 62.5%;
    }

    &--product {

        @include media-breakpoint-down(lg) {
            background: linear-gradient(0deg, #FFFFFF 0%, #E9E9E9 100%);
        }

        @include media-breakpoint-up(lg) {
            background-image: url('../images/Group-297.png');
        }
    }
    
    &-image {
        @apply w-full lg:w-1/2;

        img {
            @apply mx-auto w-1/2;
        }
    }

    &-content {
        @apply w-full lg:w-1/2;

        &__title {

            h2 {
                @apply lg:mb-6 lg:text-8xl;
            }
        }
    }
}