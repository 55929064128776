.media {

    &--speciality {

        @include media-breakpoint-down(sm) {

            + .media--speciality {
                @apply mt-8;
            }
        }
        
        @include media-breakpoint-up(sm) {
            @apply flex flex-wrap items-center;
        }
    }

    &-image {

        .media--speciality & {

            img {
                clip-path: polygon(0 0, 100% 15%, 100% 85%, 0% 100%);
            }
        }
    }

    &-content {

        .media--speciality & {
            @apply relative sm:-mt-4 sm:px-4 lg:-ml-12;
            z-index: 1;
            
            &__title {
                @apply bg-white bg-opacity-0 p-4 lg:px-16 lg:py-12;
                backdrop-filter: blur(10px);
            }
        }
    }
}