@import "~@selectize/selectize/dist/scss/selectize.scss";

.selectize {

    &-input {
        @apply bg-white px-3 py-2 text-rg border-graySemi shadow-none rounded-none leading-normal z-auto;

        &.focus {
            @apply shadow-none border-primary;
        }

        &.dropdown-active {
            @apply rounded-none;
        }

        &::after {
            @apply absolute top-1/2 right-3 mt-0 w-[1.5em] h-[1.5em] bg-no-repeat border-none #{!important};
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
            background-size: 1.5em 1.5em;
            transform: translateY(-50%);
        }

        input {
            @apply text-rg placeholder:text-graySemi;
        }
    }

    &-dropdown {
        @apply text-rg leading-normal shadow-md rounded-none;
    }
}