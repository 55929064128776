.searchbox {

    @include media-breakpoint-up(lg) {
        @apply ml-10;
    }

    &-push {

        .close {
            @apply hidden;

            .searchbox-open & {
                @apply block;
            }
        }

        .search {

            .searchbox-open & {
                @apply hidden;
            }
        }
    }

    &-dialog {
        @apply top-1/2 flex opacity-0 invisible;
        transform: translate3d(0, -100%, 0);
        transition-duration: .25s;
        transition-property: transform, opacity;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        .searchbox-open & {
            @apply opacity-100 visible;
            transform: translate3d(0, 0, 0) translateY(-50%);
        }

        &__form {
            flex: 1 0 auto;
        }

        &__input {
            padding-right: 50px #{!important};

            &:focus {

                + .searchbox-dialog__submit {

                    svg {
                        @apply fill-primary;
                    }
                }
            }
        }

        &__submit {
           
            button {
                @apply rounded-r-xs h-full;
                width: 42px;
    
                svg {
                    @apply fill-black;
                }
            }
        }
    }
}