.about {

    &-big {
        padding-top: var(--navbar-height);

        @include media-breakpoint-down(md) {
            padding-bottom: calc(var(--navbar-height) / 2);
        }

        @include media-breakpoint-up(lg) {
            height: 924px;
        }

        &__image {
            @apply absolute top-0 right-0 left-0 h-full;
            background: linear-gradient(0deg, #FFFFFF 0%, #000000 100%);

            img {
                @apply w-full h-full object-top object-cover mix-blend-screen;
                filter: grayscale(1);
            }
        }

        &__body {
            @apply lg:pt-18;

            > .title {

                > h2 {
                    @apply mb-4 lg:text-8xl uppercase;
                }
            }
        }
    }

    &-description {
        @apply lg:mt-[calc(-25%-64px)] 2xl:mt-[calc(-25%-24px)];

        &__body {
            @apply relative px-4 py-6 lg:px-22 lg:py-10 bg-white rounded-sm overflow-hidden;

            &::before {
                content: '';
                background: transparent linear-gradient(98deg, #E5342A 0%, #F7AE32 41%, #E63E24 100%);
                @apply absolute top-0 left-0 right-0 h-4;
            }

            .post {

                &-title {

                    > p {
                        @apply relative font-bold lg:text-1xl italic;

                        &::before, &::after {
                            content: '"';
                            @apply relative inline-block;
                        }
                    }
                }

                &-detail {
                    @apply lg:columns-2;
                }
            }

            .image {

                &-thumbnail {

                    img {
                        @apply w-full h-full object-cover object-center;
                    }
                }

                &-play {
                    @apply absolute top-1/2 left-1/2 transition ease-in-out;
                    transform: translate(-50%, -50%);

                    &:hover {
                        
                        &::before {
                            @apply bg-opacity-30 scale-125;
                        }
                    }

                    &::before {
                        content: '';
                        @apply bg-white bg-opacity-0 absolute top-0 right-0 left-0 w-16 h-16 lg:w-24 lg:h-24 rounded-full overflow-hidden transition-transform;
                    }

                    &__button {
                        @apply relative inline-flex items-center justify-center w-16 h-16 lg:w-24 lg:h-24 rounded-full overflow-hidden transition ease-in-out;
                        background: linear-gradient(180deg, #E5342A 0%, #F7AE32 41%, #E63E24 100%) 0% 0%;

                        svg {
                            @apply fill-white h-7;
                        }
                    }
                }
            }
        }
    }

    &-factory {

        &__title {
            @apply prose-headings:font-normal prose-headings:text-primary prose-headings:uppercase;
        }
    }
}