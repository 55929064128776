@charset "UTF-8";
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/assets/js/vendor/slick/ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("/assets/js/vendor/slick/fonts/slick.eot");
  src: url("/assets/js/vendor/slick/fonts/slick.eot?#iefix") format("embedded-opentype"), url("/assets/js/vendor/slick/fonts/slick.woff") format("woff"), url("/assets/js/vendor/slick/fonts/slick.ttf") format("truetype"), url("/assets/js/vendor/slick/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

.slick {
  @apply opacity-0;
}
.slick.slick-initialized {
  @apply opacity-100;
}
.slick--featured {
  @apply mx-1 lg:-mx-4;
}
.slick-dotted.slick-slider.slick--home {
  @apply mb-0;
}
.slick-dotted.slick-slider.slick--featured {
  @apply mb-10 lg:mb-16;
}
.slick--home .slick-slide .item-dialog {
  @apply aspect-[1/1.5] lg:aspect-[8/3.5];
}
.slick--home .slick-slide .item-body {
  @apply lg:pt-24 xl:pt-[125px];
}
.slick--home .slick-slide .item-body .image {
  @apply w-full lg:w-[55%] p-4;
}
.slick--home .slick-slide .item-body .content {
  @apply w-full lg:w-[45%] p-4;
}
.slick--home .slick-slide .item-body .content h2 {
  @apply xl:text-8xl;
}
.slick--home .slick-slide .item-body .content p {
  @apply line-clamp-3 h-18;
}
.slick--home .slick-slide .item-body .content-actions {
  @apply mt-6 lg:mt-10;
}
.slick--home .slick-slide .item-image {
  @apply lg:aspect-[8/3.5] prose-img:w-full prose-img:h-full prose-img:object-center prose-img:object-cover;
}
.slick--featured .slick-slide, .slick--factory .slick-slide {
  @apply mx-1 lg:mx-4;
}
.slick--browse .slick-slide {
  @apply mx-1 lg:mx-4 pt-4 pb-8;
}
.slick--browse .slick-slide .item {
  @apply block lg:flex p-6 lg:px-10 lg:py-12;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
}
@media (max-width: 575.98px) {
  .slick--browse .slick-slide .item {
    @apply text-center;
  }
}
@media (min-width: 992px) {
  .slick--browse .slick-slide .item {
    width: 600px;
  }
}
.slick--browse .slick-slide .item:hover .item-content__actions::before {
  @apply bg-primary;
}
.slick--browse .slick-slide .item:hover .item-content__actions .icon {
  @apply ml-3;
}
.slick--browse .slick-slide .item::before {
  content: "";
  background: transparent linear-gradient(98deg, #E5342A 0%, #F7AE32 41%, #E63E24 100%);
  @apply absolute top-0 left-0 right-0 h-4;
}
.slick--browse .slick-slide .item-image img {
  width: 150px;
}
@media (max-width: 575.98px) {
  .slick--browse .slick-slide .item-image img {
    @apply mx-auto;
  }
}
.slick--browse .slick-slide .item-content {
  @apply flex-1;
}
@media (max-width: 575.98px) {
  .slick--browse .slick-slide .item-content {
    @apply mt-6;
  }
  .slick--browse .slick-slide .item-content__title h3 {
    height: 50px;
  }
}
@media (min-width: 992px) {
  .slick--browse .slick-slide .item-content {
    @apply pl-12;
  }
}
.slick--browse .slick-slide .item-content__title {
  @apply mb-4 lg:mb-6;
}
.slick--browse .slick-slide .item-content__title h3 {
  @apply text-1xl lg:text-2xl line-clamp-2 lg:h-15;
}
.slick--browse .slick-slide .item-content__actions {
  @apply text-primary fill-primary font-bold;
  transition: margin 2.5s ease-in-out;
}
.slick--browse .slick-slide .item-content__actions::before {
  content: "";
  @apply bg-transparent absolute right-0 left-0 w-full transition ease-in-out;
  bottom: -2px;
  height: 2px;
}
.slick--factory .slick-slide .item-image {
  @apply aspect-video rounded-sm overflow-hidden;
}
.slick--factory .slick-slide .item-image img {
  @apply w-full h-full object-cover object-center;
}
.slick-prev, .slick-next {
  @apply z-30 before:hidden;
}
.slick-prev.slick-disabled, .slick-next.slick-disabled {
  @apply opacity-50;
}
.slick--home .slick-prev, .slick--featured .slick-prev, .slick--browse .slick-prev, .slick--factory .slick-prev, .slick--home .slick-next, .slick--featured .slick-next, .slick--browse .slick-next, .slick--factory .slick-next {
  @apply w-10 h-10 lg:w-14 lg:h-14 rounded-full;
  background: linear-gradient(180deg, #E5342A 0%, #F7AE32 41%, #E63E24 100%) 0% 0%;
}
.slick--home .slick-prev, .slick--featured .slick-prev, .slick--home .slick-next, .slick--featured .slick-next {
  top: calc(50% + 44px);
}
.slick--browse .slick-prev, .slick--browse .slick-next {
  top: unset;
  transform: translateY(0);
}
@media (min-width: 992px) {
  .slick--browse .slick-prev, .slick--browse .slick-next {
    bottom: -58px;
  }
}
.slick--home .slick-prev {
  @apply left-2 lg:left-5;
}
.slick--featured .slick-prev, .slick--factory .slick-prev {
  @apply left-2 lg:-left-12;
}
@media (min-width: 992px) {
  .slick--browse .slick-prev {
    left: calc(50% - 150px);
  }
}
.slick--home .slick-next {
  @apply right-2 lg:right-5;
}
.slick--featured .slick-next, .slick--factory .slick-next {
  @apply -right-2 lg:-right-12;
}
@media (min-width: 992px) {
  .slick--browse .slick-next {
    right: calc(50% - 150px);
  }
}
.slick--home .slick-dots > li, .slick--featured .slick-dots > li, .slick--browse .slick-dots > li, .slick--factory .slick-dots > li {
  @apply w-2 h-2;
}
.slick--home .slick-dots > li.slick-active, .slick--featured .slick-dots > li.slick-active, .slick--browse .slick-dots > li.slick-active, .slick--factory .slick-dots > li.slick-active {
  @apply w-4 h-4;
}
.slick--home .slick-dots > li.slick-active button, .slick--featured .slick-dots > li.slick-active button, .slick--browse .slick-dots > li.slick-active button, .slick--factory .slick-dots > li.slick-active button {
  @apply bg-primary;
}
.slick--home .slick-dots > li button, .slick--featured .slick-dots > li button, .slick--browse .slick-dots > li button, .slick--factory .slick-dots > li button {
  @apply w-full h-full p-0 bg-graySemi rounded-none;
}
.slick--home .slick-dots > li button, .slick--featured .slick-dots > li button, .slick--browse .slick-dots > li button, .slick--factory .slick-dots > li button {
  @apply before:hidden;
}
.slick--home .slick-dots {
  @apply bottom-4;
}
.slick--featured .slick-dots, .slick--browse .slick-dots, .slick--factory .slick-dots {
  @apply -bottom-10;
}

/* ===============================
=            Choices            =
=============================== */
.choices {
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;
  font-size: 16px;
}
.choices:focus {
  outline: none;
}
.choices:last-child {
  margin-bottom: 0;
}
.choices.is-open {
  overflow: visible;
}
.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: #eaeaea;
  cursor: not-allowed;
  user-select: none;
}
.choices.is-disabled .choices__item {
  cursor: not-allowed;
}
.choices [hidden] {
  display: none !important;
}

.choices[data-type*=select-one] {
  cursor: pointer;
}
.choices[data-type*=select-one] .choices__inner {
  padding-bottom: 7.5px;
}
.choices[data-type*=select-one] .choices__input {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  margin: 0;
}
.choices[data-type*=select-one] .choices__button {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  padding: 0;
  background-size: 8px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  margin-right: 25px;
  height: 20px;
  width: 20px;
  border-radius: 10em;
  opacity: 0.25;
}
.choices[data-type*=select-one] .choices__button:hover, .choices[data-type*=select-one] .choices__button:focus {
  opacity: 1;
}
.choices[data-type*=select-one] .choices__button:focus {
  box-shadow: 0 0 0 2px #00bcd4;
}
.choices[data-type*=select-one] .choices__item[data-value=""] .choices__button {
  display: none;
}
.choices[data-type*=select-one]::after {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
}
.choices[data-type*=select-one].is-open::after {
  border-color: transparent transparent #333 transparent;
  margin-top: -7.5px;
}
.choices[data-type*=select-one][dir=rtl]::after {
  left: 11.5px;
  right: auto;
}
.choices[data-type*=select-one][dir=rtl] .choices__button {
  right: auto;
  left: 0;
  margin-left: 25px;
  margin-right: 0;
}

.choices[data-type*=select-multiple] .choices__inner,
.choices[data-type*=text] .choices__inner {
  cursor: text;
}
.choices[data-type*=select-multiple] .choices__button,
.choices[data-type*=text] .choices__button {
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-right: -4px;
  margin-bottom: 0;
  margin-left: 8px;
  padding-left: 16px;
  border-left: 1px solid #008fa1;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: 0.75;
  border-radius: 0;
}
.choices[data-type*=select-multiple] .choices__button:hover, .choices[data-type*=select-multiple] .choices__button:focus,
.choices[data-type*=text] .choices__button:hover,
.choices[data-type*=text] .choices__button:focus {
  opacity: 1;
}

.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #f9f9f9;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid #ddd;
  border-radius: 2.5px;
  font-size: 14px;
  min-height: 44px;
  overflow: hidden;
}
.is-focused .choices__inner, .is-open .choices__inner {
  border-color: #b7b7b7;
}
.is-open .choices__inner {
  border-radius: 2.5px 2.5px 0 0;
}
.is-flipped.is-open .choices__inner {
  border-radius: 0 0 2.5px 2.5px;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}
.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%;
}
[dir=rtl] .choices__list--single {
  padding-right: 4px;
  padding-left: 16px;
}
.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}
.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  background-color: #00bcd4;
  border: 1px solid #00a5bb;
  color: #fff;
  word-break: break-all;
  box-sizing: border-box;
}
.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}
[dir=rtl] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: 3.75px;
}
.choices__list--multiple .choices__item.is-highlighted {
  background-color: #00a5bb;
  border: 1px solid #008fa1;
}
.is-disabled .choices__list--multiple .choices__item {
  background-color: #aaaaaa;
  border: 1px solid #919191;
}

.choices__list--dropdown, .choices__list[aria-expanded] {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility;
}
.is-active.choices__list--dropdown, .is-active.choices__list[aria-expanded] {
  visibility: visible;
}
.is-open .choices__list--dropdown, .is-open .choices__list[aria-expanded] {
  border-color: #b7b7b7;
}
.is-flipped .choices__list--dropdown, .is-flipped .choices__list[aria-expanded] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: 0.25rem 0.25rem 0 0;
}
.choices__list--dropdown .choices__list, .choices__list[aria-expanded] .choices__list {
  position: relative;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}
.choices__list--dropdown .choices__item, .choices__list[aria-expanded] .choices__item {
  position: relative;
  padding: 10px;
  font-size: 14px;
}
[dir=rtl] .choices__list--dropdown .choices__item, [dir=rtl] .choices__list[aria-expanded] .choices__item {
  text-align: right;
}
@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable, .choices__list[aria-expanded] .choices__item--selectable {
    padding-right: 100px;
  }
  .choices__list--dropdown .choices__item--selectable::after, .choices__list[aria-expanded] .choices__item--selectable::after {
    content: attr(data-select-text);
    font-size: 12px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  [dir=rtl] .choices__list--dropdown .choices__item--selectable, [dir=rtl] .choices__list[aria-expanded] .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px;
  }
  [dir=rtl] .choices__list--dropdown .choices__item--selectable::after, [dir=rtl] .choices__list[aria-expanded] .choices__item--selectable::after {
    right: auto;
    left: 10px;
  }
}
.choices__list--dropdown .choices__item--selectable.is-highlighted, .choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  background-color: #f2f2f2;
}
.choices__list--dropdown .choices__item--selectable.is-highlighted::after, .choices__list[aria-expanded] .choices__item--selectable.is-highlighted::after {
  opacity: 0.5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5;
}

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #f7f7f7;
  color: gray;
}

.choices__button {
  text-indent: -9999px;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.choices__button:focus {
  outline: none;
}

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #f9f9f9;
  font-size: 14px;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;
}
.choices__input:focus {
  outline: 0;
}
.choices__input::-webkit-search-decoration, .choices__input::-webkit-search-cancel-button, .choices__input::-webkit-search-results-button, .choices__input::-webkit-search-results-decoration {
  display: none;
}
.choices__input::-ms-clear, .choices__input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
[dir=rtl] .choices__input {
  padding-right: 2px;
  padding-left: 0;
}

.choices__placeholder {
  opacity: 0.5;
}

/* =====  End of Choices  ====== */
.choices[data-type*=select-one]::before {
  content: "";
  @apply absolute top-1/2 right-3 w-[1.5em] h-[1.5em] bg-no-repeat;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-size: 1.5em 1.5em;
  transform: translateY(-50%);
}
.choices[data-type*=select-one]::after {
  @apply hidden;
}
.choices[data-type*=select-one] .choices__inner {
  @apply pb-2;
}
.choices__inner {
  @apply bg-white min-h-[auto] px-3 py-2 border-graySemi rounded-none;
  font-size: inherit;
}
.is-focused .choices__inner, .is-open .choices__inner {
  @apply border-primary rounded-none;
}
.choices__list {
  @apply p-0;
}
.choices__list[aria-expanded] {
  @apply shadow-md rounded-none;
}
.choices__list--dropdown {
  @apply shadow-md rounded-none;
}
.choices__placeholder {
  @apply text-graySemi opacity-100;
}

.selectize-control.plugin-drag_drop.multi > .selectize-input.dragging {
  overflow: visible;
}
.selectize-control.plugin-drag_drop.multi > .selectize-input > div.ui-sortable-placeholder {
  visibility: visible !important;
  background: #f2f2f2 !important;
  background: rgba(0, 0, 0, 0.06) !important;
  border: 0 none !important;
  box-shadow: inset 0 0 12px 4px #fff;
}
.selectize-control.plugin-drag_drop .ui-sortable-placeholder::after {
  content: "!";
  visibility: hidden;
}
.selectize-control.plugin-drag_drop .ui-sortable-helper {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.selectize-dropdown.plugin-dropdown_header .selectize-dropdown-header {
  position: relative;
  padding: 10px 8px;
  border-bottom: 1px solid #d0d0d0;
  background: #f8f8f8;
  border-radius: 3px 3px 0 0;
}
.selectize-dropdown.plugin-dropdown_header .selectize-dropdown-header-close {
  position: absolute;
  right: 8px;
  top: 50%;
  color: #303030;
  opacity: 0.4;
  margin-top: -12px;
  line-height: 20px;
  font-size: 20px !important;
  text-decoration: none;
}
.selectize-dropdown.plugin-dropdown_header .selectize-dropdown-header-close:hover {
  color: black;
}

.selectize-dropdown.plugin-optgroup_columns .selectize-dropdown-content {
  display: flex;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup {
  border-right: 1px solid #f2f2f2;
  border-top: 0 none;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
  border-right: 0 none;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup:before {
  display: none;
}
.selectize-dropdown.plugin-optgroup_columns .optgroup-header {
  border-top: 0 none;
}

.selectize-control.plugin-remove_button .item {
  display: inline-flex;
  align-items: center;
  padding-right: 0 !important;
}
.selectize-control.plugin-remove_button .item .remove {
  color: inherit;
  text-decoration: none;
  vertical-align: middle;
  display: inline-block;
  padding: 2px 6px;
  border-left: 1px solid #d0d0d0;
  border-radius: 0 2px 2px 0;
  box-sizing: border-box;
  margin-left: 6px;
}
.selectize-control.plugin-remove_button .item .remove:hover {
  background: rgba(0, 0, 0, 0.05);
}
.selectize-control.plugin-remove_button .item.active .remove {
  border-left-color: #cacaca;
}
.selectize-control.plugin-remove_button .disabled .item .remove:hover {
  background: none;
}
.selectize-control.plugin-remove_button .disabled .item .remove {
  border-left-color: white;
}

.selectize-control.plugin-clear_button .clear {
  text-decoration: none;
  display: flex;
  position: absolute;
  height: 100%;
  width: 25px;
  top: 0;
  right: calc(8px - 6px);
  color: rgb(0, 0, 0);
  opacity: 0.4;
  font-weight: bold;
  border: none;
  cursor: pointer;
  z-index: 1;
  font-size: 21px;
  justify-content: center;
  align-items: center;
}
.selectize-control.plugin-clear_button .clear:hover {
  opacity: 1;
}
.selectize-control.plugin-clear_button.single .clear {
  right: calc(8px - 6px + 1.5rem);
}

.selectize-dropdown.plugin-auto_position.selectize-position-top {
  border-top: 1px solid #d0d0d0;
  border-bottom: 0 none;
  border-radius: 3px 3px 0 0;
  box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.18);
}

.selectize-control.plugin-auto_position .selectize-input.selectize-position-top.dropdown-active {
  border-radius: 0 0 3px 3px;
  border-top: 0 none;
}
.selectize-control.plugin-auto_position .selectize-input.selectize-position-top.dropdown-active::before {
  top: 0;
  bottom: unset;
}

.selectize-control {
  position: relative;
}

.selectize-dropdown,
.selectize-input,
.selectize-input input {
  color: #303030;
  font-family: inherit;
  font-size: 13px;
  line-height: 18px;
  font-smoothing: inherit;
}

.selectize-input,
.selectize-control.single .selectize-input.input-active {
  background: #fff;
  cursor: text;
  display: inline-block;
}

.selectize-input {
  border: 1px solid #d0d0d0;
  padding: 8px 8px;
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
.selectize-control.multi .selectize-input.has-items {
  padding: calc(
      8px - 2px - 0px
    ) 8px calc(
      8px - 2px - 3px -
        0px
    );
}
.selectize-input.full {
  background-color: #fff;
}
.selectize-input.disabled, .selectize-input.disabled * {
  cursor: default !important;
}
.selectize-input.focus {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15);
}
.selectize-input.dropdown-active {
  border-radius: 3px 3px 0 0;
}
.selectize-input > * {
  vertical-align: baseline;
  display: inline-block;
  zoom: 1;
}
.selectize-control.multi .selectize-input > div {
  cursor: pointer;
  margin: 0 3px 3px 0;
  padding: 2px 6px;
  background: #f2f2f2;
  color: #303030;
  border: 0px solid #d0d0d0;
}
.selectize-control.multi .selectize-input > div.active {
  background: #e8e8e8;
  color: #303030;
  border: 0px solid #cacaca;
}
.selectize-control.multi .selectize-input.disabled > div, .selectize-control.multi .selectize-input.disabled > div.active {
  color: #7d7d7d;
  background: white;
  border: 0px solid white;
}
.selectize-input > input {
  display: inline-block !important;
  padding: 0 !important;
  min-height: 0 !important;
  max-height: none !important;
  max-width: 100% !important;
  margin: 0 !important;
  text-indent: 0 !important;
  border: 0 none !important;
  background: none !important;
  line-height: inherit !important;
  user-select: auto !important;
  box-shadow: none !important;
}
.selectize-input > input::-ms-clear {
  display: none;
}
.selectize-input > input:focus {
  outline: none !important;
}
.selectize-input > input[placeholder] {
  box-sizing: initial;
}
.selectize-input.has-items > input {
  margin: 0 0px !important;
}

.selectize-input::after {
  content: " ";
  display: block;
  clear: left;
}

.selectize-input.dropdown-active::before {
  content: " ";
  display: block;
  position: absolute;
  background: #f0f0f0;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
}

.selectize-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;
  border: 1px solid #d0d0d0;
  background: #fff;
  margin: -1px 0 0 0;
  border-top: 0 none;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 3px 3px;
}
.selectize-dropdown [data-selectable] {
  cursor: pointer;
  overflow: hidden;
}
.selectize-dropdown [data-selectable] .highlight {
  background: rgba(125, 168, 208, 0.2);
  border-radius: 1px;
}
.selectize-dropdown .option,
.selectize-dropdown .optgroup-header,
.selectize-dropdown .no-results,
.selectize-dropdown .create {
  padding: 5px 8px;
}
.selectize-dropdown .option,
.selectize-dropdown [data-disabled],
.selectize-dropdown [data-disabled] [data-selectable].option {
  cursor: inherit;
  opacity: 0.5;
}
.selectize-dropdown [data-selectable].option {
  opacity: 1;
  cursor: pointer;
}
.selectize-dropdown .optgroup:first-child .optgroup-header {
  border-top: 0 none;
}
.selectize-dropdown .optgroup-header {
  color: #303030;
  background: #fff;
  cursor: default;
}
.selectize-dropdown .active {
  background-color: #e8e8e8;
  color: #303030;
}
.selectize-dropdown .active.create {
  color: #495c68;
}
.selectize-dropdown .selected {
  background-color: #e8e8e8;
  color: #303030;
}
.selectize-dropdown .create {
  color: rgba(48, 48, 48, 0.5);
}
.selectize-dropdown .active:not(.selected) {
  background: #f5fafd;
  color: #495c68;
}

.selectize-dropdown-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
  overflow-scrolling: touch;
}

.selectize-dropdown-emptyoptionlabel {
  text-align: center;
}

.selectize-dropdown .spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 5px 8px;
}
.selectize-dropdown .spinner:after {
  content: " ";
  display: block;
  width: 24px;
  height: 24px;
  margin: 3px;
  border-radius: 50%;
  border: 5px solid #d0d0d0;
  border-color: #d0d0d0 transparent #d0d0d0 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.selectize-control.single .selectize-input,
.selectize-control.single .selectize-input input {
  cursor: pointer;
}
.selectize-control.single .selectize-input.input-active, .selectize-control.single .selectize-input.input-active input:not(:read-only) {
  cursor: text;
}
.selectize-control.single .selectize-input:after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -3px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #808080 transparent transparent transparent;
}
.selectize-control.single .selectize-input.dropdown-active:after {
  margin-top: -4px;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #808080 transparent;
}

.selectize-control.rtl {
  text-align: right;
}
.selectize-control.rtl.single .selectize-input:after {
  left: 15px;
  right: auto;
}
.selectize-control.rtl .selectize-input > input {
  margin: 0 4px 0 -2px !important;
}

.selectize-control .selectize-input.disabled {
  opacity: 0.5;
  background-color: #fafafa;
}

.selectize-input {
  @apply bg-white px-3 py-2 text-rg border-graySemi shadow-none rounded-none leading-normal z-auto;
}
.selectize-input.focus {
  @apply shadow-none border-primary;
}
.selectize-input.dropdown-active {
  @apply rounded-none;
}
.selectize-input::after {
  @apply absolute top-1/2 right-3 mt-0 w-[1.5em] h-[1.5em] bg-no-repeat border-none !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-size: 1.5em 1.5em;
  transform: translateY(-50%);
}
.selectize-input input {
  @apply text-rg placeholder:text-graySemi;
}
.selectize-dropdown {
  @apply text-rg leading-normal shadow-md rounded-none;
}

:root {
  --base-color: #2A2A2A;
  --white-color: #fff;
  --navbar-height: 88px;
  --navbar-background: #F8F8F8;
  --loading-size: 1.25rem;
  --loading-sm-size: 1rem;
  --loading-lg-size: 1.5rem;
  --loading-xl-size: 2rem;
}

.btn {
  --primary-color: linear-gradient(180deg, #E5342A 0%, #F7AE32 41%, #E63E24 100%) 0% 0%;
  --primary-color-invert: white;
  --primary-color-hover: #f69f0d;
  --secondary-color: #2A2A2A;
  --secondary-color-invert: white;
  --secondary-color-hover: #F7AE32;
  --tertiary-color: #fff;
  --tertiary-color-invert: #F7AE32;
  --tertiary-color-hover: #F7AE32;
  --button-padding-y: 12px;
  --button-padding-x: 24px;
  --button-rounded: 4px;
  --button-sm-padding-y: 8px;
  --button-sm-padding-x: 12px;
  @apply inline-flex items-center text-center border border-transparent font-bold;
  padding: var(--button-padding-y) var(--button-padding-x);
  font-size: var(--button-font-size);
  border-radius: var(--button-rounded);
  outline: 0;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-sm {
  padding: var(--button-sm-padding-y) var(--button-sm-padding-x);
}
.btn-primary {
  background: var(--primary-color) !important;
  color: var(--primary-color-invert);
}
.btn-primary svg {
  fill: var(--primary-color-invert);
}
.btn-primary:hover {
  @apply underline;
}
.btn-outline-primary {
  @apply bg-white;
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.btn-outline-primary svg {
  fill: var(--primary-color);
}
.btn-outline-primary:hover {
  border-color: var(--primary-color-hover);
}
.btn-outline-primary:hover svg {
  fill: var(--primary-color);
}
.btn-secondary {
  background: var(--secondary-color) !important;
  color: var(--secondary-color-invert);
}
.btn-secondary svg {
  fill: var(--secondary-color-invert);
}
.btn-secondary:hover {
  @apply underline;
}
.btn-outline-secondary {
  @apply bg-white;
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}
.btn-outline-secondary svg {
  fill: var(--secondary-color);
}
.btn-outline-secondary:hover {
  border-color: var(--secondary-color-hover);
}
.btn-outline-secondary:hover svg {
  fill: var(--secondary-color);
}
.btn-tertiary {
  background: var(--tertiary-color) !important;
  color: var(--tertiary-color-invert);
}
.btn-tertiary svg {
  fill: var(--tertiary-color-invert);
}
.btn-tertiary:hover {
  @apply underline;
}
.btn-outline-tertiary {
  @apply bg-white;
  border-color: var(--tertiary-color);
  color: var(--tertiary-color);
}
.btn-outline-tertiary svg {
  fill: var(--tertiary-color);
}
.btn-outline-tertiary:hover {
  border-color: var(--tertiary-color-hover);
}
.btn-outline-tertiary:hover svg {
  fill: var(--tertiary-color);
}

.card {
  @apply transition ease-in-out;
}
.card--speciality {
  @apply p-6;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}
@media (min-width: 576px) {
  .card--speciality {
    @apply py-10 px-4;
  }
}
.card--speciality:hover .card-header {
  @apply opacity-0;
}
.card--speciality:hover .card-content {
  @apply opacity-100;
}
.card--featured {
  @apply border border-transparent;
}
.card--featured:hover {
  @apply border-primary;
}
.card--featured:hover .card-content__actions {
  @apply opacity-100;
}
.card--list {
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
}
@media (max-width: 575.98px) {
  .card--list {
    @apply text-center;
  }
}
@media (min-width: 992px) {
  .card--list {
    padding-top: 56px !important;
  }
}
.card--list::before {
  content: "";
  background: linear-gradient(100deg, #E5342A 0%, #F7AE32 41%, #E63E24 100%);
  @apply absolute top-0 right-0 left-0 h-4;
}
.card--news:hover .card-header img {
  @apply scale-110;
}
.card--gallery {
  border-radius: 24px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
}
.card--speciality .card-header {
  @apply flex flex-col items-center justify-center h-full opacity-100 transition ease-in-out;
}
.card--speciality .card-header h3 {
  @apply mt-4 text-1xl h-[50px];
}
@media (min-width: 576px) {
  .card--speciality .card-header h3 {
    @apply line-clamp-2;
  }
}
.card--news .card-header img, .card--list .card-header img {
  @apply w-full h-full object-cover object-center;
}
.card--news .card-header img {
  transition: transform 0.5s ease-in-out;
}
.card--list .card-header {
  width: 150px;
  height: 150px;
}
@media (max-width: 575.98px) {
  .card--list .card-header {
    @apply mx-auto mb-4;
  }
}
.card--gallery .card-header img {
  @apply mx-auto;
  height: 150px;
}
.card--speciality .card-content {
  @apply absolute top-0 right-6 left-6 flex items-center justify-center text-center h-full opacity-0 transition ease-in-out;
}
.card--news .card-content > p {
  @apply line-clamp-3 h-18;
}
.card--list .card-content {
  @apply flex-1 sm:pl-0 pl-16;
}
.card--news .card-content__title h6 {
  @apply line-clamp-2 h-10;
}
.card--news-trending .card-content__title h6 {
  @apply lg:text-2xl line-clamp-1;
}
@media (min-width: 992px) {
  .card--news-trending .card-content__title h6 {
    height: 30px;
  }
}
.card--featured .card-content__title > h5 {
  @apply mb-2 line-clamp-2 h-10 lg:h-[50px];
}
@media (max-width: 991.98px) {
  .card--featured .card-content__title > h5 {
    @apply text-rg;
  }
}
.card--featured .card-content__title > p {
  @apply line-clamp-3 h-18;
}
.card--list .card-content__title {
  @apply mb-4 lg:mb-10;
}
@media (max-width: 767.98px) {
  .card--list .card-content__title > h5 {
    height: 50px;
  }
}
.card--list .card-content__title > h5 {
  @apply lg:text-2xl line-clamp-2 lg:h-15;
}
.card--news .card-content__actions {
  @apply text-primary fill-primary font-bold;
}
.card--news .card-content__actions::before {
  content: "";
  @apply bg-primary absolute right-0 left-0 w-full;
  bottom: -2px;
  height: 1px;
}
.card--featured .card-content__actions {
  @apply bg-primary text-white font-bold w-full py-3 text-center opacity-0 transition-opacity ease-in-out;
}

@media (max-width: 575.98px) {
  .media--speciality + .media--speciality {
    @apply mt-8;
  }
}
@media (min-width: 576px) {
  .media--speciality {
    @apply flex flex-wrap items-center;
  }
}
.media--speciality .media-image img {
  clip-path: polygon(0 0, 100% 15%, 100% 85%, 0% 100%);
}
.media--speciality .media-content {
  @apply relative sm:-mt-4 sm:px-4 lg:-ml-12;
  z-index: 1;
}
.media--speciality .media-content__title {
  @apply bg-white bg-opacity-0 p-4 lg:px-16 lg:py-12;
  backdrop-filter: blur(10px);
}

.form-input, .form-textarea, .form-select {
  @apply w-full px-3 py-2 text-black border-gray rounded-sm transition ease-in-out ring-0 placeholder:text-graySemi focus:border-primary !important;
}
.form-input[disabled], .form-textarea[disabled], .form-select[disabled] {
  @apply bg-gray;
}
.form-prefix .form-input, .form-prefix .form-textarea, .form-prefix .form-select {
  padding-left: calc(var(--form-prefix-space) + 12px) !important;
}
.form-validate .form-input, .form-validate .form-textarea, .form-validate .form-select {
  @apply border-primary !important;
}
.form-validate__feedback {
  @apply mt-1 text-primary;
}
.form-validate__feedback small {
  @apply text-sm;
}
.form-prefix {
  --form-prefix-space: 54px;
}
.form-prefix__icon {
  @apply absolute top-1/2 inline-flex items-center justify-center border-r border-gray;
  width: var(--form-prefix-space);
  height: calc(100% - 12px);
  transform: translateY(-50%);
}
@media (max-width: 991.98px) {
  .form-captcha {
    transform: scale(0.9);
  }
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }
  50% {
    background-position-y: -2px;
  }
  100% {
    background-position-y: 0;
  }
}
.form-check {
  @apply relative inline-flex items-center cursor-pointer;
}
.form-check input[type=checkbox]:checked {
  @apply bg-primary border-primary;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  animation: checkmark 0.2s ease-in;
}
.form-check input[type=radio] {
  transition: 0.25s;
}
.form-check input[type=radio]:checked {
  @apply bg-primary border-primary;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
  animation: checkmark 0.2s ease-in;
}
.form-check label {
  @apply inline-block flex-1 pl-2 mb-0 cursor-pointer;
}

.navbar {
  transition: ease-in-out 0.25s, height 0.25s;
}
@media (max-width: 991.98px) {
  .navbar {
    @apply bg-grayLight;
    height: var(--navbar-height);
  }
}
.navbar.has-hamburger {
  @apply bg-white;
}
.navbar--fixed.navbar--single {
  @apply bg-white bg-opacity-50;
  backdrop-filter: blur(5px);
  height: var(--navbar-height);
}
.navbar--fixed.navbar--single .navbar-dialog {
  @apply items-center;
}
.navbar--fixed.navbar--single .navbar-logo {
  @apply lg:py-0;
}
.navbar--fixed.navbar--single .navbar-logo img {
  @apply h-16;
}
.navbar--fixed.navbar--single .navbar-menu, .navbar--fixed.navbar--single .navbar-action {
  @apply lg:py-0;
}
.navbar--default {
  @apply bg-white bg-opacity-50;
  backdrop-filter: blur(5px);
  height: var(--navbar-height);
}
@media (max-width: 991.98px) {
  .navbar-dialog {
    @apply items-center;
  }
}
.navbar--default .navbar-dialog {
  @apply items-center;
}
.navbar--single .navbar-logo {
  @apply lg:w-[150px] lg:py-7;
}
@media (max-width: 991.98px) {
  .navbar--single .navbar-logo img {
    @apply h-16;
  }
}
.navbar--default .navbar-logo img {
  @apply h-16;
}
.navbar--single .navbar-menu, .navbar--single .navbar-action {
  @apply lg:py-10;
}
.navbar-menu {
  @apply hidden lg:flex;
}
.navbar-action {
  @apply hidden lg:block;
}
.navbar--single .navbar-action {
  @apply lg:w-[150px];
}

@media (max-width: 991.98px) {
  .footer-logo img {
    @apply mx-auto;
  }
}
@media (max-width: 991.98px) {
  .footer-row {
    @apply pt-8;
  }
}
.footer-row__menu .address-item {
  @apply inline-flex;
}
.footer-row__menu .address-item__icon {
  @apply w-4 mt-1 text-center;
}
.footer-row__menu .store-item img {
  @apply h-6;
}

.dropdown {
  @apply relative;
}
.dropdown.active .dropdown-toggle {
  @apply border-primary;
}
.dropdown.active .dropdown-toggle .icon {
  @apply rotate-180;
  transition: transform 0.25s;
}
.dropdown.active .dropdown-item {
  @apply block;
}
.dropdown-toggle {
  @apply inline-flex items-center cursor-pointer;
}
.dropdown-toggle .image img {
  @apply h-4;
}
.dropdown-item {
  @apply bg-white absolute hidden top-10 min-w-[100px] h-auto py-2 px-3 border border-primary rounded-xs overflow-hidden z-50;
}
.dropdown-item--end {
  @apply lg:right-0;
}
.dropdown-item a {
  @apply relative text-black hover:text-primary;
}
.dropdown-item a.active::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMTYiIHdpZHRoPSIxNCIgdmlld0JveD0iMCAwIDQ0OCA1MTIiPjwhLS0hRm9udCBBd2Vzb21lIEZyZWUgNi41LjEgYnkgQGZvbnRhd2Vzb21lIC0gaHR0cHM6Ly9mb250YXdlc29tZS5jb20gTGljZW5zZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tL2xpY2Vuc2UvZnJlZSBDb3B5cmlnaHQgMjAyMyBGb250aWNvbnMsIEluYy4tLT48cGF0aCBvcGFjaXR5PSIxIiBmaWxsPSIjMUUzMDUwIiBkPSJNNDM4LjYgMTA1LjRjMTIuNSAxMi41IDEyLjUgMzIuOCAwIDQ1LjNsLTI1NiAyNTZjLTEyLjUgMTIuNS0zMi44IDEyLjUtNDUuMyAwbC0xMjgtMTI4Yy0xMi41LTEyLjUtMTIuNS0zMi44IDAtNDUuM3MzMi44LTEyLjUgNDUuMyAwTDE2MCAzMzguNyAzOTMuNCAxMDUuNGMxMi41LTEyLjUgMzIuOC0xMi41IDQ1LjMgMHoiLz48L3N2Zz4=");
}
.dropdown-item a::before {
  content: "";
  @apply relative inline-block bg-no-repeat bg-contain w-3 h-3 mr-1;
}
.dropdown-item a .image img {
  @apply h-4;
}
.navbar--fixed .dropdown-item {
  @apply top-[38px];
}

.jumbotron {
  padding-top: var(--navbar-height);
}
@media (max-width: 767.98px) {
  .jumbotron {
    padding-bottom: calc(var(--navbar-height) / 2);
  }
}
.jumbotron::before {
  content: "";
  background: linear-gradient(0deg, #FFFFFF 0%, #E9E9E9 100%);
  @apply absolute right-0 left-0 h-full;
  top: var(--navbar-height);
  z-index: -1;
}
@media (min-width: 768px) {
  .jumbotron::before {
    height: 650px;
  }
}
.jumbotron-image img {
  @apply mix-blend-multiply;
}
@media (max-width: 575.98px) {
  .jumbotron-image {
    @apply aspect-video overflow-hidden;
  }
  .jumbotron-image img {
    @apply w-full h-full object-cover object-bottom;
  }
}
@media (min-width: 768px) {
  .jumbotron-image {
    @apply absolute right-0;
    top: var(--navbar-height);
  }
  .jumbotron-image img {
    height: 300px;
  }
  .jumbotron--news .jumbotron-image {
    top: 0;
  }
  .jumbotron--news .jumbotron-image img {
    height: 450px;
  }
}
@media (min-width: 1200px) {
  .jumbotron-image img {
    height: 350px;
  }
}
@media (max-width: 767.98px) {
  .jumbotron--search .jumbotron-body {
    @apply mt-8;
  }
}
@media (min-width: 768px) {
  .jumbotron-body {
    @apply pt-16;
  }
}
.jumbotron-body__title > h2 {
  @apply mb-4 lg:text-8xl uppercase;
}
.jumbotron--search .jumbotron-body__title > h2 {
  @apply lg:text-4xl;
  text-transform: none;
}
.jumbotron--search .jumbotron-body__title > h2 span {
  @apply relative font-bold;
}
.jumbotron--search .jumbotron-body__title > h2 span::before, .jumbotron--search .jumbotron-body__title > h2 span::after {
  content: '"';
  @apply relative inline-block;
}
.jumbotron--product .jumbotron-body__title p {
  @apply lg:w-[45%];
}

.breadcrumb {
  @apply mb-1;
}
.breadcrumb-item {
  @apply text-sm;
}
.breadcrumb-item + .breadcrumb-item {
  @apply pl-1;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: "/";
  @apply inline-block float-left pr-1 text-black;
}
.breadcrumb-item.active {
  @apply text-primary;
}

.hero {
  margin-top: var(--navbar-height);
}
@media (min-width: 992px) {
  .hero {
    background-size: 62.5%;
  }
}
@media (max-width: 991.98px) {
  .hero--product {
    background: linear-gradient(0deg, #FFFFFF 0%, #E9E9E9 100%);
  }
}
@media (min-width: 992px) {
  .hero--product {
    background-image: url("../images/Group-297.png");
  }
}
.hero-image {
  @apply w-full lg:w-1/2;
}
.hero-image img {
  @apply mx-auto w-1/2;
}
.hero-content {
  @apply w-full lg:w-1/2;
}
.hero-content__title h2 {
  @apply lg:mb-6 lg:text-8xl;
}

.accordion-item:first-child {
  @apply rounded-t-xs;
}
.accordion-item:not(:first-of-type) {
  @apply border-t-0;
}
.accordion-item:last-child {
  @apply rounded-b-xs;
}
.accordion-toggle[aria-expanded=true] {
  background-color: inherit;
  transition-duration: 0.75s;
  transition-property: background-color, border-color;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.accordion-toggle[aria-expanded=true] .icon svg {
  @apply rotate-180;
}
.accordion-toggle[aria-expanded=false] {
  transition-duration: 0.75s;
  transition-property: background-color, border-color;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.accordion-toggle .title {
  @apply flex-1 pr-4;
}
.accordion-toggle .icon svg {
  @apply h-3 fill-black;
  transition-duration: 0.5s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.accordion-content {
  transition-duration: 0.5s;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.accordion-content[aria-hidden=true] {
  height: 0;
  opacity: 0;
  transform: translate3d(0, -0.5rem, 0);
  visibility: hidden;
}
.accordion-content[aria-hidden=false] {
  height: auto;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  visibility: visible;
}

.tab-link[aria-selected=true] {
  @apply bg-gray border-b-black;
}

.modal.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.25s;
}

@media (min-width: 992px) {
  .searchbox {
    @apply ml-10;
  }
}
.searchbox-push .close {
  @apply hidden;
}
.searchbox-open .searchbox-push .close {
  @apply block;
}
.searchbox-open .searchbox-push .search {
  @apply hidden;
}
.searchbox-dialog {
  @apply top-1/2 flex opacity-0 invisible;
  transform: translate3d(0, -100%, 0);
  transition-duration: 0.25s;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.searchbox-open .searchbox-dialog {
  @apply opacity-100 visible;
  transform: translate3d(0, 0, 0) translateY(-50%);
}
.searchbox-dialog__form {
  flex: 1 0 auto;
}
.searchbox-dialog__input {
  padding-right: 50px !important;
}
.searchbox-dialog__input:focus + .searchbox-dialog__submit svg {
  @apply fill-primary;
}
.searchbox-dialog__submit button {
  @apply rounded-r-xs h-full;
  width: 42px;
}
.searchbox-dialog__submit button svg {
  @apply fill-black;
}

.hamburger {
  @apply relative block lg:hidden w-10 h-10;
}
.hamburger-push {
  @apply flex items-center w-full h-full transition ease-in-out;
}
.hamburger-push > span {
  @apply bg-primary absolute top-5 block w-10 h-1 transition ease-in-out;
}
.hamburger-push > span::before, .hamburger-push > span::after {
  content: "";
  @apply bg-primary absolute w-10 h-1 transition ease-in-out;
}
.hamburger-push > span::before {
  @apply -top-[10px];
}
.hamburger-push > span::after {
  @apply top-[10px];
}
.hamburger-push.show > span {
  @apply bg-transparent;
}
.hamburger-push.show > span::before {
  transform: rotateZ(45deg) scaleX(1) translate(8px, 8px);
}
.hamburger-push.show > span::after {
  transform: rotateZ(-45deg) scaleX(1) translate(7px, -7px);
}
.hamburger-menu {
  top: var(--navbar-height);
  transform: translate3d(0, -100%, 0);
  height: calc(100vh - var(--navbar-height));
}
.hamburger-menu.show {
  @apply visible opacity-100;
  transform: translate3d(0, 0, 0);
}
.hamburger-search__input {
  padding-right: 50px !important;
}
.hamburger-search__input:focus + .hamburger-search__submit svg {
  @apply fill-primary;
}
.hamburger-search__submit button {
  @apply rounded-r-xs h-full;
  width: 42px;
}
.hamburger-search__submit button svg {
  @apply fill-black;
}

.pagination-item__button {
  @apply text-black font-bold;
  line-height: 24px;
}
.pagination-item__button.current {
  @apply text-primary lg:text-2xl;
}
.pagination-item__button.disabled {
  @apply text-gray;
}

.tooltip {
  --tooltip-background-color: #354856;
  --tooltip-text-color: #fff;
  --tooltip-position-top: auto;
  --tooltip-position-bottom: calc(100% + 8px);
  --tooltip-position-border-top: auto;
  --tooltip-position-border-bottom: 100%;
  --tooltip-position-left: 50%;
  --tooltip-position-right: auto;
  --tooltip-position-border-left: 50%;
  --tooltip-position-border-right: auto;
  @apply relative inline-flex;
}
.tooltip::before, .tooltip::after {
  @apply absolute pointer-events-none opacity-0 transition-opacity;
}
.tooltip::before {
  content: attr(data-tooltip);
  color: var(--tooltip-text-color);
  background-color: var(--tooltip-background-color);
  @apply absolute w-max py-1 px-2 text-sm;
}
.tooltip::after {
  @apply block w-0 h-0 border-solid border-4;
  content: "";
  border-color: var(--tooltip-background-color) transparent transparent transparent;
}
.tooltip:hover::before, .tooltip:hover::after {
  @apply opacity-100;
  transition-delay: 75ms;
}
.tooltip--position::before, .tooltip--position::after {
  transform: translate(-50%);
}
.tooltip--position::before {
  top: var(--tooltip-position-top);
  bottom: var(--tooltip-position-bottom);
  left: var(--tooltip-position-left);
  right: var(--tooltip-position-right);
}
.tooltip--position::after {
  top: var(--tooltip-position-border-top);
  bottom: var(--tooltip-position-border-bottom);
  left: var(--tooltip-position-border-left);
  right: var(--tooltip-position-border-right);
  border-color: var(--tooltip-background-color) transparent transparent transparent;
}

.loading {
  @apply inline-block aspect-square pointer-events-none;
  background-color: currentColor;
  width: var(--loading-size);
  mask-size: 100%;
  mask-repeat: no-repeat;
  mask-position: center;
}
.loading-sm {
  width: var(--loading-sm-size);
}
.loading-lg {
  width: var(--loading-lg-size);
}
.loading-xl {
  width: var(--loading-xl-size);
}
.loading-primary {
  color: var(--primary-color);
}
.loading-secondary {
  color: var(--secondary-color);
}
.loading-spinner {
  mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHN0cm9rZT0nIzAwMCcgdmlld0JveD0nMCAwIDI0IDI0JyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxzdHlsZT4uc3Bpbm5lcl9WOG0xe3RyYW5zZm9ybS1vcmlnaW46Y2VudGVyO2FuaW1hdGlvbjpzcGlubmVyX3pLb2EgMnMgbGluZWFyIGluZmluaXRlfS5zcGlubmVyX1Y4bTEgY2lyY2xle3N0cm9rZS1saW5lY2FwOnJvdW5kO2FuaW1hdGlvbjpzcGlubmVyX1lwWlMgMS41cyBlYXNlLW91dCBpbmZpbml0ZX1Aa2V5ZnJhbWVzIHNwaW5uZXJfektvYXsxMDAle3RyYW5zZm9ybTpyb3RhdGUoMzYwZGVnKX19QGtleWZyYW1lcyBzcGlubmVyX1lwWlN7MCV7c3Ryb2tlLWRhc2hhcnJheTowIDE1MDtzdHJva2UtZGFzaG9mZnNldDowfTQ3LjUle3N0cm9rZS1kYXNoYXJyYXk6NDIgMTUwO3N0cm9rZS1kYXNob2Zmc2V0Oi0xNn05NSUsMTAwJXtzdHJva2UtZGFzaGFycmF5OjQyIDE1MDtzdHJva2UtZGFzaG9mZnNldDotNTl9fTwvc3R5bGU+PGcgY2xhc3M9J3NwaW5uZXJfVjhtMSc+PGNpcmNsZSBjeD0nMTInIGN5PScxMicgcj0nOS41JyBmaWxsPSdub25lJyBzdHJva2Utd2lkdGg9JzMnPjwvY2lyY2xlPjwvZz48L3N2Zz4=);
}
.loading-ring {
  mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nNDQnIGhlaWdodD0nNDQnIHZpZXdCb3g9JzAgMCA0NCA0NCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJyBzdHJva2U9JyNmZmYnPjxnIGZpbGw9J25vbmUnIGZpbGwtcnVsZT0nZXZlbm9kZCcgc3Ryb2tlLXdpZHRoPScyJz48Y2lyY2xlIGN4PScyMicgY3k9JzIyJyByPScxJz48YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSdyJyBiZWdpbj0nMHMnIGR1cj0nMS44cycgdmFsdWVzPScxOyAyMCcgY2FsY01vZGU9J3NwbGluZScga2V5VGltZXM9JzA7IDEnIGtleVNwbGluZXM9JzAuMTY1LCAwLjg0LCAwLjQ0LCAxJyByZXBlYXRDb3VudD0naW5kZWZpbml0ZScgLz48YW5pbWF0ZSBhdHRyaWJ1dGVOYW1lPSdzdHJva2Utb3BhY2l0eScgYmVnaW49JzBzJyBkdXI9JzEuOHMnIHZhbHVlcz0nMTsgMCcgY2FsY01vZGU9J3NwbGluZScga2V5VGltZXM9JzA7IDEnIGtleVNwbGluZXM9JzAuMywgMC42MSwgMC4zNTUsIDEnIHJlcGVhdENvdW50PSdpbmRlZmluaXRlJyAvPjwvY2lyY2xlPjxjaXJjbGUgY3g9JzIyJyBjeT0nMjInIHI9JzEnPjxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9J3InIGJlZ2luPSctMC45cycgZHVyPScxLjhzJyB2YWx1ZXM9JzE7IDIwJyBjYWxjTW9kZT0nc3BsaW5lJyBrZXlUaW1lcz0nMDsgMScga2V5U3BsaW5lcz0nMC4xNjUsIDAuODQsIDAuNDQsIDEnIHJlcGVhdENvdW50PSdpbmRlZmluaXRlJyAvPjxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9J3N0cm9rZS1vcGFjaXR5JyBiZWdpbj0nLTAuOXMnIGR1cj0nMS44cycgdmFsdWVzPScxOyAwJyBjYWxjTW9kZT0nc3BsaW5lJyBrZXlUaW1lcz0nMDsgMScga2V5U3BsaW5lcz0nMC4zLCAwLjYxLCAwLjM1NSwgMScgcmVwZWF0Q291bnQ9J2luZGVmaW5pdGUnIC8+PC9jaXJjbGU+PC9nPjwvc3ZnPg==);
}

.post-detail {
  @apply prose-headings:mb-4 prose-p:mb-4 prose-a:text-primary prose-a:font-bold prose-img:mb-4 prose-ul:mb-4 prose-ol:mb-4 prose-ul:pl-4 prose-ol:pl-4 prose-ul:list-disc prose-ol:list-decimal;
}
.post-detail p:last-child {
  margin-bottom: 0 !important;
}
.post-detail img {
  @apply inline-block rounded-sm;
}

@media (max-width: 991.98px) {
  .main.page-index {
    margin-top: var(--navbar-height);
  }
}

.stray {
  @apply h-screen;
}
.stray:before {
  content: "";
  @apply absolute right-0 left-0;
  background: linear-gradient(0deg, #FFFFFF 0%, #e9e9e9 100%);
  height: calc(100vh - var(--navbar-height));
  top: var(--navbar-height);
}
.stray-dialog {
  @apply border-b border-b-primary;
}
.stray-image {
  @apply absolute top-0 right-0 left-0 h-screen;
}
.stray-image img {
  @apply w-full h-full object-cover object-center;
}
.stray-body__title {
  @apply font-bold prose-headings:text-primary prose-headings:text-6xl lg:prose-headings:text-[88px] prose-p:text-2xl lg:prose-p:text-4xl;
}

.about--primary {
  background-image: url("../images/Group14.png"), url("../images/Group4.png");
  background-position: left top, 190% 82%;
}
@media (min-width: 1400px) {
  .about--primary {
    background-position: left top, 160% 82%;
  }
}
.about-intro {
  @apply relative lg:h-[560px];
}
.about-intro__body .title {
  @apply xl:ml-[25%];
}
.about-intro__body .title h2 {
  @apply lg:text-6xl;
}
.about-intro__body .title-excerpt h6 {
  @apply text-2xl;
}
.about-intro__body .image {
  @apply lg:absolute lg:top-0 lg:right-0 lg:w-[calc(50%-32px)] 3xl:relative 3xl:w-full;
}
.about-intro__body .image img {
  @apply lg:w-full;
}
.about-intro__body .image-play {
  @apply absolute top-1/2 left-1/2 transition ease-in-out;
  transform: translate(-50%, -50%);
}
.about-intro__body .image-play:hover::before {
  @apply bg-opacity-30 scale-125;
}
.about-intro__body .image-play::before {
  content: "";
  @apply bg-white bg-opacity-0 absolute top-0 right-0 left-0 w-16 h-16 lg:w-24 lg:h-24 rounded-full overflow-hidden transition-transform;
}
.about-intro__body .image-play__button {
  @apply relative inline-flex items-center justify-center w-16 h-16 lg:w-24 lg:h-24 rounded-full overflow-hidden transition ease-in-out;
  background: linear-gradient(180deg, #E5342A 0%, #F7AE32 41%, #E63E24 100%) 0% 0%;
}
.about-intro__body .image-play__button svg {
  @apply fill-white h-7;
}
.about-speciality__body .title {
  @apply mx-auto lg:w-[700px] prose-h2:mb-4 prose-h2:font-normal prose-h2:text-primary lg:prose-p:text-1xl prose-p:font-bold;
}
.about-speciality__body .title h2 {
  @apply lg:text-6xl;
}
.about-speciality__body .content-row {
  @apply -m-2 lg:-m-3;
}
.about-speciality__body .content-row > div {
  @apply w-full md:w-1/2 lg:w-1/4 p-2 lg:p-3;
}

.product-feature__body .title {
  @apply mx-auto lg:w-[700px] prose-h2:mb-4 prose-h2:font-normal prose-h2:text-primary lg:prose-p:text-1xl prose-p:font-bold;
}
.product-feature__body .title h2 {
  @apply lg:text-6xl;
}
.product-feature__body .content-actions {
  @apply pt-10;
}

.news-list .title {
  @apply flex items-center justify-between;
}

@media (max-width: 991.98px) {
  .contact--actions {
    @apply bg-right;
  }
}
.contact-content .title h2 {
  @apply lg:text-8xl;
}

.product--page {
  background-image: url("../images/Group14.png"), url("../images/Group4.png");
  background-position: left 135%, 160% 160%;
}
@media (min-width: 768px) {
  .product--page {
    @apply mt-18;
  }
}
@media (min-width: 1200px) {
  .product--page {
    @apply mt-24;
  }
}
.product--post {
  background-image: url("../images/Group14.png"), url("../images/Group4.png");
  background-position: left 135%, 160% 160%;
}
.product-list__body {
  @apply flex flex-wrap justify-center -m-4;
}
.product-list__body > div {
  @apply w-full lg:w-1/2 p-4;
}
.product-gallery__title {
  @apply mx-auto lg:w-5/6 mb-6 lg:mb-12 lg:px-25;
}
.product-description__body {
  @apply lg:bg-white lg:py-16 lg:px-25 overflow-hidden;
}
@media (min-width: 992px) {
  .product-description__body {
    border-radius: 24px;
  }
}
.product-description__title {
  @apply lg:mb-12;
}
.product--browse .product-title {
  @apply prose-headings:font-normal prose-headings:text-primary prose-headings:uppercase;
}

.speciality--page {
  background-image: url("../images/Group14.png"), url("../images/Group4.png");
  background-position: left 135%, 160% 160%;
}
@media (min-width: 992px) {
  .speciality--page {
    @apply mt-18;
  }
}
@media (min-width: 1200px) {
  .speciality--page {
    @apply mt-24;
  }
}
.speciality-body > .title {
  @apply lg:prose-headings:text-2xl prose-headings:italic;
}
@media (min-width: 768px) {
  .speciality-body > .listing > div:nth-of-type(even) .media-image {
    @apply order-2;
  }
  .speciality-body > .listing > div:nth-of-type(even) .media-image img {
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 85%);
  }
  .speciality-body > .listing > div:nth-of-type(even) .media-content {
    @apply -mr-12 ml-0 order-1;
  }
}

.news--detail::before {
  content: "";
  background: linear-gradient(0deg, #FFFFFF 0%, #E9E9E9 100%);
  @apply absolute top-0 right-0 left-0 h-full;
  z-index: -1;
}
@media (min-width: 768px) {
  .news--detail::before {
    height: 650px;
  }
}
@media (min-width: 992px) {
  .news--page {
    @apply mt-18;
  }
}
.news--detail {
  margin-top: var(--navbar-height);
}
.news-trending {
  @apply border-b border-gray;
}
.news-trending__body .title {
  @apply lg:prose-headings:text-4xl prose-headings:uppercase;
}
.news-related {
  @apply lg:mt-14;
}
.news-related__title {
  @apply lg:prose-headings:text-4xl prose-headings:font-normal prose-headings:uppercase;
}

.contact--page {
  background-image: url("../images/Group14.png");
  background-position: left 24%;
}
@media (max-width: 575.98px) {
  .contact--page {
    background-size: 50%;
  }
}
@media (min-width: 768px) {
  .contact--page {
    @apply mt-24;
  }
}
@media (min-width: 992px) {
  .contact--page {
    background-position: left 135%;
  }
}
.contact--success {
  background: linear-gradient(0deg, #FFFFFF 0%, #e9e9e9 100%);
  margin-top: var(--navbar-height);
  @apply border-b border-b-primary;
}
@media (min-width: 992px) {
  .contact--success {
    height: calc(100vh - var(--navbar-height));
  }
}
.contact-body__left {
  position: sticky;
  top: calc(var(--navbar-height) * 1.5);
}
.contact-body__left > h3 {
  @apply mb-4 lg:text-4xl;
}
.contact-body .address-item__icon {
  @apply w-5;
}
.contact-body .address-item__icon svg {
  @apply mx-auto;
}
.contact-body .maps {
  @apply relative rounded-sm overflow-hidden;
  height: calc(475px - 15vh);
}
@media (min-width: 992px) {
  .contact-body .maps {
    height: 475px;
  }
}
.contact-body .maps::before {
  content: "";
  background: transparent linear-gradient(98deg, #E5342A 0%, #F7AE32 41%, #E63E24 100%);
  @apply absolute top-0 left-0 right-0 h-4;
}
.contact-body .maps > * {
  @apply w-full h-full;
}
.contact-body .talk > h5 {
  @apply mb-10 lg:mb-14 text-center;
}
@media (min-width: 992px) {
  .contact-success__body {
    height: calc(100vh - var(--navbar-height));
  }
}
@media (min-width: 992px) {
  .contact-success__body > .image {
    @apply absolute top-0 left-0 w-1/2 h-full;
  }
}
.contact-success__body > .image img {
  @apply w-full h-full object-cover;
}
.contact-success__body .content {
  @apply lg:ml-auto lg:pl-25 lg:w-1/2;
}
@media (max-width: 991.98px) {
  .contact-success__body .content {
    @apply py-8;
  }
}

.about-big {
  padding-top: var(--navbar-height);
}
@media (max-width: 767.98px) {
  .about-big {
    padding-bottom: calc(var(--navbar-height) / 2);
  }
}
@media (min-width: 992px) {
  .about-big {
    height: 924px;
  }
}
.about-big__image {
  @apply absolute top-0 right-0 left-0 h-full;
  background: linear-gradient(0deg, #FFFFFF 0%, #000000 100%);
}
.about-big__image img {
  @apply w-full h-full object-top object-cover mix-blend-screen;
  filter: grayscale(1);
}
.about-big__body {
  @apply lg:pt-18;
}
.about-big__body > .title > h2 {
  @apply mb-4 lg:text-8xl uppercase;
}
.about-description {
  @apply lg:mt-[calc(-25%-64px)] 2xl:mt-[calc(-25%-24px)];
}
.about-description__body {
  @apply relative px-4 py-6 lg:px-22 lg:py-10 bg-white rounded-sm overflow-hidden;
}
.about-description__body::before {
  content: "";
  background: transparent linear-gradient(98deg, #E5342A 0%, #F7AE32 41%, #E63E24 100%);
  @apply absolute top-0 left-0 right-0 h-4;
}
.about-description__body .post-title > p {
  @apply relative font-bold lg:text-1xl italic;
}
.about-description__body .post-title > p::before, .about-description__body .post-title > p::after {
  content: '"';
  @apply relative inline-block;
}
.about-description__body .post-detail {
  @apply lg:columns-2;
}
.about-description__body .image-thumbnail img {
  @apply w-full h-full object-cover object-center;
}
.about-description__body .image-play {
  @apply absolute top-1/2 left-1/2 transition ease-in-out;
  transform: translate(-50%, -50%);
}
.about-description__body .image-play:hover::before {
  @apply bg-opacity-30 scale-125;
}
.about-description__body .image-play::before {
  content: "";
  @apply bg-white bg-opacity-0 absolute top-0 right-0 left-0 w-16 h-16 lg:w-24 lg:h-24 rounded-full overflow-hidden transition-transform;
}
.about-description__body .image-play__button {
  @apply relative inline-flex items-center justify-center w-16 h-16 lg:w-24 lg:h-24 rounded-full overflow-hidden transition ease-in-out;
  background: linear-gradient(180deg, #E5342A 0%, #F7AE32 41%, #E63E24 100%) 0% 0%;
}
.about-description__body .image-play__button svg {
  @apply fill-white h-7;
}
.about-factory__title {
  @apply prose-headings:font-normal prose-headings:text-primary prose-headings:uppercase;
}