.news {

    &--detail {

        &::before {
            content: '';
            background: linear-gradient(0deg, #FFFFFF 0%, #E9E9E9 100%);
            @apply absolute top-0 right-0 left-0 h-full;
            z-index: -1;

            @include media-breakpoint-up(md) {
                height: 650px;
            }
        }
    }

    &--page {

        @include media-breakpoint-up(lg) {
            @apply mt-18;
        }
    }

    &--detail {
        margin-top: var(--navbar-height);
    }

    &-trending {
        @apply border-b border-gray;

        &__body {

            .title {
                @apply lg:prose-headings:text-4xl prose-headings:uppercase;
            }
        }
    }

    &-related {
        @apply lg:mt-14;
        
        &__title {
            @apply lg:prose-headings:text-4xl prose-headings:font-normal prose-headings:uppercase;
        }
    }
}