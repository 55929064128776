$root-variables: (
    // base color
    base-color: #2A2A2A,
    white-color: #fff,
    // navbar
    navbar-height: 88px,
    navbar-background: #F8F8F8,

    // loading regular
    loading-size: 1.25rem,

    // loading small
    loading-sm-size: 1rem,

    // loading large
    loading-lg-size: 1.5rem,

    // loading extra large
    loading-xl-size: 2rem,
);

:root {

    @each $key, $value in $root-variables {
      --#{$key}: #{$value};
    }
  }
  