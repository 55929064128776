.product {
    
    &--page {
        background-image: url('../images/Group14.png'), url('../images/Group4.png');
        background-position: left 135%, 160% 160%;

        @include media-breakpoint-up(md) {
            @apply mt-18;
        }

        @include media-breakpoint-up(xl) {
            @apply mt-24;
        }
    }
    
    &--post {
        background-image: url('../images/Group14.png'), url('../images/Group4.png');
        background-position: left 135%, 160% 160%;
    }

    &-list {

        &__body {
            @apply flex flex-wrap justify-center -m-4;

            > div {
                @apply w-full lg:w-1/2 p-4;
            }
        }
    }

    &-gallery {

        &__title {
            @apply mx-auto lg:w-5/6 mb-6 lg:mb-12 lg:px-25;
        }
    }

    &-description {

        &__body {
            @apply lg:bg-white lg:py-16 lg:px-25 overflow-hidden;
            
            @include media-breakpoint-up(lg) {
                border-radius: 24px;
            }
        }

        &__title {
            @apply lg:mb-12;
        }
    }

    &-title {

        .product--browse & {
            @apply prose-headings:font-normal prose-headings:text-primary prose-headings:uppercase;
        }
    }
}