.stray {
    @apply h-screen;

    &:before {
        content: '';
        @apply absolute right-0 left-0;
        background: linear-gradient(0deg, #FFFFFF 0%, #e9e9e9 100%);
        height: calc(100vh - var(--navbar-height));
        top: var(--navbar-height);
    }

    &-dialog {
        @apply border-b border-b-primary;
    }

    &-image {
        @apply absolute top-0 right-0 left-0 h-screen;

        img {
            @apply w-full h-full object-cover object-center;
        }
    }

    &-body {

        &__title {
            @apply font-bold prose-headings:text-primary prose-headings:text-6xl lg:prose-headings:text-[88px] prose-p:text-2xl lg:prose-p:text-4xl;
        }
    }
}