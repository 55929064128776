.footer {

    &-logo {

        @include media-breakpoint-down(lg) {

            img {
                @apply mx-auto;
            }
        }
    }
    
    &-row {

        @include media-breakpoint-down(lg) {
            @apply pt-8;
        }

        &__menu {
            
            .address {

                &-item {
                    @apply inline-flex;

                    &__icon {
                        @apply w-4 mt-1 text-center;
                    }
                }
            }

            .store {

                &-item {

                    img {
                        @apply h-6;
                    }
                }
            }
        }
    }
}