.dropdown {
    @apply relative;

    &.active {
        
        .dropdown {

            &-toggle {
                @apply border-primary;

                .icon {
                    @apply rotate-180;
                    transition: transform .25s;
                }
            }

            &-item {
                @apply block;
            }
        }
    }

    &-toggle {
        @apply inline-flex items-center cursor-pointer;

        .image {

            img {
                @apply h-4;
            }
        }
    }

    &-item {
        @apply bg-white absolute hidden top-10 min-w-[100px] h-auto py-2 px-3 border border-primary rounded-xs overflow-hidden z-50;

        &--end {
            @apply lg:right-0;
        }

        a {
            @apply relative text-black hover:text-primary;

            &.active {

                &::before {
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMTYiIHdpZHRoPSIxNCIgdmlld0JveD0iMCAwIDQ0OCA1MTIiPjwhLS0hRm9udCBBd2Vzb21lIEZyZWUgNi41LjEgYnkgQGZvbnRhd2Vzb21lIC0gaHR0cHM6Ly9mb250YXdlc29tZS5jb20gTGljZW5zZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tL2xpY2Vuc2UvZnJlZSBDb3B5cmlnaHQgMjAyMyBGb250aWNvbnMsIEluYy4tLT48cGF0aCBvcGFjaXR5PSIxIiBmaWxsPSIjMUUzMDUwIiBkPSJNNDM4LjYgMTA1LjRjMTIuNSAxMi41IDEyLjUgMzIuOCAwIDQ1LjNsLTI1NiAyNTZjLTEyLjUgMTIuNS0zMi44IDEyLjUtNDUuMyAwbC0xMjgtMTI4Yy0xMi41LTEyLjUtMTIuNS0zMi44IDAtNDUuM3MzMi44LTEyLjUgNDUuMyAwTDE2MCAzMzguNyAzOTMuNCAxMDUuNGMxMi41LTEyLjUgMzIuOC0xMi41IDQ1LjMgMHoiLz48L3N2Zz4=");
                }
            }

            &::before {
                content: '';
                @apply relative inline-block bg-no-repeat bg-contain w-3 h-3 mr-1;
            }

            .image {
    
                img {
                    @apply h-4;
                }
            }
        }

        .navbar--fixed & {
            @apply top-[38px];
        }
    }
}