.navbar {
    transition: ease-in-out .25s, height .25s;

    @include media-breakpoint-down(lg) {
        @apply bg-grayLight;
        height: var(--navbar-height);
    }

    &.has-hamburger {
        @apply bg-white;
    }

    &--fixed {
        
        &.navbar--single {
            @apply bg-white bg-opacity-50;
            backdrop-filter: blur(5px);
            height: var(--navbar-height);

            .navbar {

                &-dialog {
                    @apply items-center;
                }

                &-logo {
                    @apply lg:py-0;

                    img {
                        @apply h-16;
                    }
                }

                &-menu, &-action {
                    @apply lg:py-0;
                }
            }
        }
    }

    &--default {
        @apply bg-white bg-opacity-50;
        backdrop-filter: blur(5px);
        height: var(--navbar-height);
    }

    &-dialog {
        
        @include media-breakpoint-down(lg) {
            @apply items-center;
        }

        .navbar--default & {
            @apply items-center;
        }
    }

    &-logo {
        
        .navbar--single & {
            @apply lg:w-[150px] lg:py-7;

            @include media-breakpoint-down(lg) {

                img {
                    @apply h-16;
                }
            }
        }

        .navbar--default & {
            
            img {
                @apply h-16;
            }
        }
    }

    &-menu, &-action {

        .navbar--single & {
            @apply lg:py-10;
        }
    }

    &-menu {
        @apply hidden lg:flex;
    }

    &-action {
        @apply hidden lg:block;

        .navbar--single & {
            @apply lg:w-[150px];
        }
    }
}