.about {

    &--primary {
        background-image: url('../images/Group14.png'), url('../images/Group4.png');
        background-position: left top, 190% 82%;

        @include media-breakpoint-up(xxl) {
            background-position: left top, 160% 82%;
        }
    }

    &-intro {
        @apply relative lg:h-[560px];

        &__body {

            .title {
                @apply xl:ml-[25%];

                h2 {
                    @apply lg:text-6xl;
                }

                &-excerpt {

                    h6 {
                        @apply text-2xl;
                    }
                }
            }

            .image {
                @apply lg:absolute lg:top-0 lg:right-0 lg:w-[calc(50%-32px)] 3xl:relative 3xl:w-full;
                    
                img {
                    @apply lg:w-full;
                }

                &-play {
                    @apply absolute top-1/2 left-1/2 transition ease-in-out;
                    transform: translate(-50%, -50%);

                    &:hover {
                        
                        &::before {
                            @apply bg-opacity-30 scale-125;
                        }
                    }

                    &::before {
                        content: '';
                        @apply bg-white bg-opacity-0 absolute top-0 right-0 left-0 w-16 h-16 lg:w-24 lg:h-24 rounded-full overflow-hidden transition-transform;
                    }

                    &__button {
                        @apply relative inline-flex items-center justify-center w-16 h-16 lg:w-24 lg:h-24 rounded-full overflow-hidden transition ease-in-out;
                        background: linear-gradient(180deg, #E5342A 0%, #F7AE32 41%, #E63E24 100%) 0% 0%;

                        svg {
                            @apply fill-white h-7;
                        }
                    }
                }
            }
        }
    }

    &-speciality {

        &__body {

            .title {
                @apply mx-auto lg:w-[700px] prose-h2:mb-4 prose-h2:font-normal prose-h2:text-primary lg:prose-p:text-1xl prose-p:font-bold;

                h2 {
                    @apply lg:text-6xl;
                }
            }

            .content {

                &-row {
                    @apply -m-2 lg:-m-3;

                    > div {
                        @apply w-full md:w-1/2 lg:w-1/4 p-2 lg:p-3;
                    }
                }
            }
        }
    }
}

.product {

    &-feature {        

        &__body {

            .title {
                @apply mx-auto lg:w-[700px] prose-h2:mb-4 prose-h2:font-normal prose-h2:text-primary lg:prose-p:text-1xl prose-p:font-bold;
    
                h2 {
                    @apply lg:text-6xl;
                }
            }

            .content {

                &-actions {
                    @apply pt-10;
                }
            }
        }
    }
}

.news {

    &-list {
        
        .title {
            @apply flex items-center justify-between;
        }
    }
}

.contact {

    &--actions {

        @include media-breakpoint-down(lg) {
            @apply bg-right;
        }
    }

    &-content {

        .title {
            
            h2 {
                @apply lg:text-8xl;
            }
        }
    }
}